import React, { useContext, useEffect, useState } from "react";
import { useNavigate, generatePath } from "react-router-dom";

import { ThemeContext } from "../../../context/ThemeContext";
import useTitle from "../../../hooks/useTitle";

import { useGetAcMainDashboardDataQuery } from "../../../features/ac/acMainDashboardApi";
import { useAcSyncMutation } from "../../../features/ac/acMainDashboardApi";
import DangerStatus from "../../dashboard/thresholdStatus/DangerStatus";
import SafeStatus from "../../dashboard/thresholdStatus/SafeStatus";

import Swal from "sweetalert2";

function AcMainDashboard() {
  useTitle("Ac Main Dashboard");
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();

  // const { data, isLoading } = useGetAmanDashboardCardDataQuery();
  const { data: acMainDashboardData, isLoading } =
    useGetAcMainDashboardDataQuery();

  const [acSync] = useAcSyncMutation();

  const acSyncHandler = (branch_id) => {
    console.log(branch_id);
    Swal.fire({
      title: "Processing...",
      text: "Please wait",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    acSync({ ac_branch_id: branch_id })
      .unwrap()
      .then((payload) => {
        if (payload?.code == "201") {
          // Swal.fire("Success!", payload.status, "success");
          Swal.fire(payload.status, "", "success");
        } else {
          Swal.fire(payload?.status, "", "error");
        }
      })
      .catch((error) => console.log(error?.status));
  };

  const acBranchClickHandler = (branchId, branch_name) => {
    const path = generatePath("/ac-dashboard-branchwise/:branchId", {
      branchId: branchId,
    });
    // navigate(path);
    const url = window.location.origin + path;
    // window.open(url, "_blank");
    window.open(`${url}?branch_name=${branch_name}`, "_blank");
  };

  return (
    <div className={`${theme}`}>
      <div className="row">
        {/* <div className="col-md-4">
          <div className="card theme-background">
            <div className="aman-card-title theme-text">
              Total Ac {acMainDashboardData?.total_ac}
            </div>
            <div className="" style={{ padding: "20px" }}>
              <div className="aman-text theme-text">
                <div className="aman-text-container">
                  <div className="text-only-container">
                    <div className="text-only">
                      {acMainDashboardData?.total_on_ac} Connected
                    </div>
                  </div>
                  <div className="text-value"></div>
                </div>
                <div className="aman-text-container">
                  <div className="text-only-container">
                    <div className="text-only">
                      {acMainDashboardData?.total_off_ac}{" "}
                      <span style={{ color: "#AE2626" }}>Disconnected</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-md-3">
          <div className="card theme-background">
            {/* <div className="aman-card-title theme-text">Generator</div> */}
            <div className="" style={{ padding: "20px" }}>
              {/* <div className="aman-icon">
                <img src={generatorIcon}></img>
              </div> */}
              <div className="aman-text theme-text">
                <div className="aman-text-container">
                  <div className="text-only fs-3 fw-bold">Total Ac</div>
                  <div className="text-value fs-3 fw-bold">
                    {" "}
                    {acMainDashboardData?.total_ac || 0}
                  </div>
                </div>
                <div className="aman-text-container">
                  <div className="text-only-container">
                    {/* <div className="aman-status">
                      <SafeStatus />
                    </div> */}
                    <div className="text-only fw-bold">AC On</div>
                  </div>
                  <div className="text-value fw-bold">
                    {" "}
                    {acMainDashboardData?.total_on_ac || 0}
                  </div>
                </div>
                <div className="aman-text-container">
                  <div className="text-only-container">
                    {/* <div className="aman-status">
                      <DangerStatus />
                    </div> */}
                    <div className="text-only fw-bold">AC Off</div>
                  </div>
                  <div className="text-value fw-bold">
                    {" "}
                    {acMainDashboardData?.total_off_ac || 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card theme-background">
            {/* <div className="aman-card-title theme-text">Generator</div> */}
            <div className="" style={{ padding: "20px" }}>
              {/* <div className="aman-icon">
                <img src={generatorIcon}></img>
              </div> */}
              <div className="aman-text theme-text">
                <div className="aman-text-container">
                  <div className="text-only fs-3 fw-bold">Total Branch</div>
                  <div className="text-value fs-3 fw-bold">
                    {" "}
                    {acMainDashboardData?.total_branches || 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-4">
          <div className="card theme-background">
            <div
              className="aman-card-title theme-text"
              style={{ padding: "20px" }}
            >
              Total Branch {acMainDashboardData?.total_branches}
            </div>
          </div>
        </div> */}

        <div className="generator-list-container">
          <div
            className="theme-text"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            All Branch Devices
          </div>
          {/* <div className="">
            <form onSubmit={(e) => generatorSearchHandler(e)}>
              <div className="row">
                <div className="col-md-2 date-search-submit-btn">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="company_id"
                    onChange={generatorDataChangeHandler}
                  >
                    <option value="" selected>
                      Select Company
                    </option>
                    {generatorCompanyList?.map((companyInfo) => {
                      return (
                        <option value={companyInfo.id}>
                          {companyInfo?.company_name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-md-2 date-search-submit-btn">
                  <button type="submit" class="btn btn-primary col-md-12">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div> */}
          <div className="list-table-container">
            <table className="list-table">
              <tr className="theme-text">
                <th>Status</th>
                <th>Branches</th>
                <th>Num of AC</th>
                <th>On</th>
                <th>Off</th>
                <th>Sync</th>
              </tr>

              {acMainDashboardData?.data?.map((branchData) => {
                return (
                  <tr className="theme-text">
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "none",
                        justifyContent: "center",
                      }}
                    >
                      {branchData?.device_status == "1" ? (
                        <SafeStatus />
                      ) : (
                        <DangerStatus />
                      )}
                    </td>
                    <td
                      className="clickable-td theme-text"
                      onClick={() =>
                        acBranchClickHandler(
                          branchData?.branch_id,
                          branchData?.branch_name
                        )
                      }
                    >
                      {branchData?.branch_name}
                    </td>

                    <td>{branchData?.total_ac}</td>
                    <td>{branchData?.on_count}</td>
                    <td>{branchData?.off_count}</td>
                    <td
                      className="clickable-td theme-text"
                      onClick={() => acSyncHandler(branchData?.branch_id)}
                    >
                      Click to Sync
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcMainDashboard;
