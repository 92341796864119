import React, { useState } from "react";
import styles from "./acDashboard.module.css";
import temp_icon from "./assets/temp_icon.png";
import { IoMdArrowDropup } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";
// import { PiSnowflakeLight } from "react-icons/pi";

import { FaPlusCircle } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";

import { FaSnowflake } from "react-icons/fa";

import { FiSun } from "react-icons/fi";
import { MdOutlineWaterDrop } from "react-icons/md";

// import { PiFanDuotone } from "react-icons/pi";
import { FaFan } from "react-icons/fa";
import DangerStatus from "../../dashboard/thresholdStatus/DangerStatus";
import SafeStatus from "../../dashboard/thresholdStatus/SafeStatus";

import Swal from "sweetalert2";
import { useAcOnOffMutation } from "../../../features/ac/acBranchWiseAcDashboard";
import { useAcModeSwitchMutation } from "../../../features/ac/acBranchWiseAcDashboard";
import { useAcScheduleManualSwitchMutation } from "../../../features/ac/acBranchWiseAcDashboard";

import { useAcTempValueChangeMutation } from "../../../features/ac/acBranchWiseAcDashboard";

function AcCard({ acInfo }) {
  const [isChecked, setIsChecked] = useState(acInfo?.status === "on");
  const [scheduleManualIsChecked, setScheduleManualIsChecked] = useState(
    acInfo?.schedule_status === "schedule"
  );
  const [acMode, setAcMode] = useState(acInfo?.mode);

  const [acTemp, setAcTemp] = useState(acInfo?.temp);

  const [acOnOff] = useAcOnOffMutation();

  const [acModeSwitch] = useAcModeSwitchMutation();

  const [acScheduleManualSwitch] = useAcScheduleManualSwitchMutation();

  const [acTempValueChange] = useAcTempValueChangeMutation();

  const handleToggle = (event) => {
    const acStatus = event.target.checked ? "on" : "off";
    // setIsChecked(event.target.checked);
    Swal.fire({
      title: "Processing...",
      text: "Please wait",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    acOnOff({
      branch_id: acInfo?.branch_id,
      ac_id: acInfo?.peripheral_unit_id,
      on_off: acStatus,
    })
      .unwrap()
      .then((payload) => {
        if (payload?.code == "201") {
          Swal.fire("Updated!", payload?.message, "success");
          setIsChecked(!isChecked);
        } else {
          Swal.fire("Failed!", payload?.message, "error");
        }

        // console.log(payload?.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleScheduleToggle = (event) => {
    const scheduleStatus = event.target.checked ? "schedule" : "manual";
    // alert(event.target.checked);
    // setScheduleManualIsChecked(event.target.checked);

    Swal.fire({
      title: "Processing...",
      text: "Please wait",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    acScheduleManualSwitch({
      branch_id: acInfo?.branch_id,
      ac_id: acInfo?.peripheral_unit_id,
      schedule_status: scheduleStatus,
    })
      .unwrap()
      .then((payload) => {
        if (payload?.code == "201") {
          Swal.fire("Updated!", payload?.message, "success");
          setScheduleManualIsChecked(!scheduleManualIsChecked);
        } else {
          Swal.fire("Failed!", payload?.message, "error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleModeChange = (mode) => {
    Swal.fire({
      title: "Processing...",
      text: "Please wait",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    acModeSwitch({
      id: acInfo?.peripheral_unit_id,
      mode: mode,
    })
      .unwrap()
      .then((payload) => {
        if (payload?.code == "201") {
          Swal.fire("Updated!", payload.message, "success");
          setAcMode(mode);
        } else {
          Swal.fire("Failed!", payload?.message, "error");
        }

        // console.log(payload?.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const increaseTemp = async () => {
    const newTemp = Math.min(acTemp + 1, 32);
    setAcTemp(newTemp);

    Swal.fire({
      title: "Processing...",
      text: "Please wait",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    await delay(200); // Wait for 10 seconds

    try {
      const payload = await acTempValueChange({
        id: acInfo?.peripheral_unit_id,
        temperature: newTemp, // Use the new temperature
      }).unwrap();

      if (payload?.code == "201") {
        Swal.fire("Updated!", payload.message, "success");
      } else {
        Swal.fire("Failed!", payload?.message, "error");
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Error!", "An error occurred.", "error");
    }
  };

  const decreaseTemp = async () => {
    // setAcTemp((prevTemp) => Math.max(prevTemp - 1, 16));
    const newTemp = Math.max(acTemp - 1, 16);
    setAcTemp(newTemp);

    Swal.fire({
      title: "Processing...",
      text: "Please wait",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    await delay(200); // Wait for 10 seconds

    try {
      const payload = await acTempValueChange({
        id: acInfo?.peripheral_unit_id,
        temperature: newTemp, // Use the new temperature
      }).unwrap();

      if (payload?.code == "201") {
        Swal.fire("Updated!", payload.message, "success");
      } else {
        Swal.fire("Failed!", payload?.message, "error");
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Error!", "An error occurred.", "error");
    }
  };

  return (
    <div>
      {/* <div
        className={`${styles.card} ${styles.card_data_section} ${
          acInfo?.ct_status == "on"
            ? styles.green_border_blink
            : styles.red_border
        } theme-background`} 
         
        //border blink chillox only not in shwapno
      > */}
      <div
        className={`${styles.card} ${styles.card_data_section} 
            theme-background`}
      >
        <div className={`${styles.title_portion}`}>
          <div className={`${styles.title} theme-text`}>
            {acInfo?.peripheral_type} (
            <span
              style={{
                color:
                  acInfo?.schedule_status == "manual" ? "#f05959" : "#45ba53",
              }}
            >
              {acInfo?.schedule_status === "schedule" ? "Schedule" : "Manual"}
            </span>
            )
          </div>
          {/* <div style={{ marginTop: "5px" }}>
            {acInfo?.ct_status == "on" ? <SafeStatus /> : <DangerStatus />}
          </div> */}
          <div className={`${styles.toggle}`}>
            <div>
              <label class="switch">
                <input
                  type="checkbox"
                  checked={scheduleManualIsChecked}
                  onChange={handleScheduleToggle}
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <div className={`theme-text ${styles.temperature_text}`}>
          Temperature
        </div>
        <div className={`${styles.icon_value_portion}`}>
          <div className={`${styles.icon}`}>
            <img src={temp_icon} alt="temp_icon"></img>
          </div>{" "}
          <div>
            <FaMinusCircle
              className="theme-text"
              size="20px"
              onClick={!scheduleManualIsChecked ? decreaseTemp : ""}
              style={{
                cursor: scheduleManualIsChecked ? "not-allowed" : "pointer",
                opacity: scheduleManualIsChecked ? ".7" : "1",
              }}
            />
          </div>
          <div
            className={`${styles.value} ${styles.no_select} theme-text`}
            style={{ opacity: scheduleManualIsChecked ? ".7" : "1" }}
          >
            {acTemp != null ? `${acTemp}°C` : "--"}
          </div>
          <div>
            <FaPlusCircle
              className="theme-text"
              size="20px"
              onClick={!scheduleManualIsChecked ? increaseTemp : ""}
              style={{
                cursor: scheduleManualIsChecked ? "not-allowed" : "pointer",
                opacity: scheduleManualIsChecked ? ".7" : "1",
              }}
            />
          </div>
          {/* <div className={`${styles.arrow_section}`}>
            <div>
              <IoMdArrowDropup size={40} color="#7CB9FD" />
            </div>
            <div>
              <IoMdArrowDropdown size={40} color="#7CB9FD" />
            </div>
          </div> */}
          <div className={`${styles.on_off_switch_section}`}>
            <label className={`${styles.switch}`}>
              <input
                type="checkbox"
                className={`${styles.chk}`}
                checked={isChecked}
                onChange={handleToggle}
                disabled={scheduleManualIsChecked}
              />
              <span
                className={`${styles.slider}`}
                style={{ opacity: scheduleManualIsChecked ? 0.5 : 1 }}
              ></span>
            </label>
          </div>
        </div>
        {/* <img src={coolIcon} style={{ color: "red" }}></img> */}
        <div className={`${styles.mode_container}`}>
          <div
            className={`${styles.mode_card} ${
              acMode === "heat" ? styles.mode_card_active : ""
            }`}
            onClick={() => handleModeChange("heat")}
          >
            <div>
              <FiSun
                // size="20"
                style={{ height: "21px", width: "21px" }}
              />
            </div>
            <div className={`${styles.mode_text}`}>Heat</div>
          </div>
          <div
            className={`${styles.mode_card} ${
              acMode === "cool" ? styles.mode_card_active : ""
            }`}
            onClick={() => handleModeChange("cool")}
          >
            <div>
              <FaSnowflake
                // size="20"
                style={{ height: "21px", width: "21px" }}
              />
            </div>
            <div className={`${styles.mode_text}`}>Cool</div>
          </div>
          <div
            className={`${styles.mode_card} ${
              acMode === "dry" ? styles.mode_card_active : ""
            }`}
            onClick={() => handleModeChange("dry")}
          >
            <div>
              <MdOutlineWaterDrop
                // size="20"
                style={{ height: "21px", width: "21px" }}
              />
            </div>
            <div className={`${styles.mode_text}`}>Dry</div>
          </div>
          <div
            className={`${styles.mode_card} ${
              acMode === "fan" ? styles.mode_card_active : ""
            }`}
            onClick={() => handleModeChange("fan")}
          >
            <div>
              <FaFan
                // size="20"
                style={{ height: "21px", width: "21px" }}
              />
            </div>
            <div className={`${styles.mode_text}`}>Fan</div>
          </div>
        </div>
        {/* <div className={`${styles.footer_section}`}>
          <div
            className="theme-text"
            style={{ fontSize: "15px", fontWeight: "bold" }}
          >
            Auto Room Temperature System
          </div>
          <div className={`${styles.toggle}`}>
            <div>
              <label class="switch">
                <input type="checkbox" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div> */}
        {/* <div className={`${styles.footer_section}`}>
          <div
            className="theme-text"
            style={{ fontSize: "15px", fontWeight: "bold" }}
          >
            Run Time
          </div>
          <div className={`theme-text`}>{acInfo?.duration}</div>
        </div> */}
      </div>
    </div>
  );
}

export default AcCard;
