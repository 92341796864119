import { configureStore } from "@reduxjs/toolkit";
import { apiSlice, apiSlice2 } from "../features/api/apiSlice";
import authSliceReducer from "../features/auth/authSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [apiSlice2.reducerPath]: apiSlice2.reducer,
    auth: authSliceReducer,
  },
  // devTools: process.env.NODE_ENV !== "production",
  devTools: true,
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(apiSlice.middleware, apiSlice2.middleware),
});
