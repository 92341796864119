import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { AiFillDelete } from "react-icons/ai";
import { ThemeContext } from "../../context/ThemeContext";
import { useGetAllSensorListQuery } from "../../features/sensorManagement/sensorApi";
import { Table, Pagination } from "rsuite";
import { useInsertSensorMutation } from "../../features/sensorManagement/sensorApi";
import { useUpdateSensorDataMutation } from "../../features/sensorManagement/sensorApi";
import { SkipToken } from "@reduxjs/toolkit/dist/query";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { useGetAllRoomsQuery } from "../../features/rooms/roomsApi";
import { useGetDeviceListQuery } from "../../features/slave-assign/slaveAssignApi";
import { useSensometerListFetchByDeviceIdMutation } from "../../features/room-map/roomMapApi";
import { useSensorListFetchBySensometerIdMutation } from "../../features/room-map/roomMapApi";
import { useUnassignedSlaveListBySensorIdMutation } from "../../features/room-map/roomMapApi";
import { useAssignSlaveStoreMutation } from "../../features/room-map/roomMapApi";
import { useRoomSensorMapListMutation } from "../../features/room-map/roomMapApi";
import { useDeleteSensorMapMutation } from "../../features/room-map/roomMapApi";

import { useTotalSensorListFetchQuery } from "../../features/dataLog/filters/filterApi";

import Swal from "sweetalert2";

import "./style/sensorMap.css";
import useTitle from "../../hooks/useTitle";

const animatedComponents = makeAnimated();

const { Column, HeaderCell, Cell } = Table;

function SensorMap() {
  useTitle("Sensor Map");
  const [show, setShow] = useState(false);
  const [sensorName, setSensorName] = useState();
  const [sensorId, setSensorId] = useState();
  const [sensorUnit, setSensorUnit] = useState();
  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [formData, setFormData] = useState({});
  const [reseponseMessage, setResponseMessage] = useState();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const [roomList, setRoomList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [sensometerList, setSensometerList] = useState([]);
  const [sensorList, setSensorList] = useState([]);
  const [slaveList, setSlaveList] = useState([]);
  const [allRoomMapList, setAllRoomMapList] = useState([]);

  const [searchFormData, setSearchFormData] = useState({});

  const theme = useContext(ThemeContext);

  // for sensor map
  const { data: allRoomResponse, isLoading: allRoomLoading } =
    useGetAllRoomsQuery();
  const { data: getDeviceList, isLoading: getDeviceListLoading } =
    useGetDeviceListQuery();

  const [sensometerListFetchByDeviceId] =
    useSensometerListFetchByDeviceIdMutation();
  const [sensorListFetchBySensometerId] =
    useSensorListFetchBySensometerIdMutation();

  const [unassignedSlaveListBySensorId] =
    useUnassignedSlaveListBySensorIdMutation();
  const [assignSlaveStore] = useAssignSlaveStoreMutation();

  const [roomSensorMapList] = useRoomSensorMapListMutation();

  const [deleteSensorMap] = useDeleteSensorMapMutation();

  const { data: searchSensorList, isLoading: sensorFetchQueryLoading } =
    useTotalSensorListFetchQuery();

  useEffect(() => {
    roomSensorMapList()
      .unwrap()
      .then((payload) => setAllRoomMapList(payload.data))
      .catch((error) => console.log(error?.status));
  }, [roomSensorMapList]);

  // if(roomSensorMapListResponse){
  //   console.log(roomSensorMapListResponse);
  // }

  const deviceChangeHandler = (e) => {
    const device_id = e.target.value;

    sensometerListFetchByDeviceId({
      device_id: device_id,
    })
      .unwrap()
      .then((payload) => setSensometerList(payload.data))
      .catch((error) => console.log(error?.status));
  };

  const sensometerChangeHandler = (e) => {
    const sensometer_id = e.target.value;
    sensorListFetchBySensometerId({
      senso_meter_id: sensometer_id,
    })
      .unwrap()
      .then((payload) => setSensorList(payload.data))
      .catch((error) => console.log(error?.status));
  };
  const options = [];
  const sensorChangeHandler = (e) => {
    const sensor_id = e.target.value;
    unassignedSlaveListBySensorId({
      sensor_id: sensor_id,
    })
      .unwrap()
      .then((payload) => setSlaveList(payload.data))
      .catch((error) => console.log(error?.status));
  };

  const slaveChangeHandler = (e) => {
    const value = e.map((option) => option.value);
    // console.log(value);
    setFormData((previousValue) => ({
      ...previousValue,
      slave_number: value,
    }));
  };
  useEffect(() => {
    if (!allRoomLoading) {
      if (allRoomResponse) {
        setRoomList([...allRoomResponse.data]);
        // console.log(allRoomResponse.data);
      }
    }
    if (!getDeviceListLoading) {
      if (getDeviceList) {
        setDeviceList(getDeviceList.device);
      }
    }
  }, [allRoomLoading, getDeviceListLoading]);

  // for sensor map

  //for data table

  // const { data: sensorList, isLoading } = useGetAllSensorListQuery();
  let defaultData = [];
  let data = [];

  // if(error){
  //   console.log(error.status);
  // }
  if (true) {
    defaultData = [...allRoomMapList];
    defaultData = defaultData?.map((roomMapInfo) => {
      const data = new Date(roomMapInfo.updated_at);
      const formattedDate = data.toLocaleString();
      return {
        ...roomMapInfo,
        updated_at: formattedDate,
      };
    });

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  }

  // if (!updateLoading) {
  //   console.group("Update Loading");
  //   console.log(updateApiResponse);
  //   console.groupEnd();
  // }
  //  if (!insertSensorLoading){
  //   console.group("Insert Loading");
  //   console.log(insertSensorResponse);
  //   console.groupEnd();
  // }
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  // const handleEdit = (id) => {
  //   let formData = defaultData.find((object) => object.id == id) || {};
  //   setFormData(formData);

  // };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSensorMap({ id: id })
          .unwrap()
          .then((payload) => {
            Swal.fire("Deleted!", "Your item has been deleted.", "success");
            console.log(payload?.message);
            roomSensorMapList()
              .unwrap()
              .then((payload) => setAllRoomMapList(payload?.data))
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
    // alert(id);
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
    setSensometerList([]);
    setSensorList([]);
    setSlaveList([]);
  };
  const dataChangeHandler = (e) => {
    setFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };
  //for data table

  const formSubmitHandler = (e) => {
    e.preventDefault();

    assignSlaveStore(formData)
      .unwrap()
      .then((payload) => {
        setResponseMessage(payload?.message);
        roomSensorMapList()
          .unwrap()
          .then((payload) => setAllRoomMapList(payload?.data))
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        setResponseMessage(error?.status);
        console.log(error);
      });
  };
  const handleClose = () => {
    setShow(false);
  };

  const searchSumbitHandler = (e) => {
    e.preventDefault();
    console.log(searchFormData);
    roomSensorMapList(searchFormData)
      .unwrap()
      .then((payload) => {
        setAllRoomMapList(payload?.data);
        setPage(1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchChangeHandler = (e) => {
    if (e.target.value) {
      setSearchFormData((previousValue) => ({
        ...previousValue,
        [e.target.name]: e.target.value,
      }));
    } else if (e.target.value === "") {
      delete searchFormData[e.target.name];
    }
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#f0f0f0", // Change to your desired background color for the dropdown
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: "#f0f0f0", // Same background color for consistency
      color: "#333", // Change text color
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#4CAF50"
        : state.isFocused
        ? "#c8e6c9"
        : "#f0f0f0", // Different background when focused or selected
      color: state.isSelected ? "#FFFFFF" : "#333", // Text color based on selected state
      ":active": {
        backgroundColor: "#4CAF50", // Active background color
        color: "#FFFFFF",
      },
    }),
  };

  return (
    <div className={`row row-sm ${theme} `}>
      <div
        className="theme-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Sensor Map
      </div>
      <div className="search-container">
        <div
          className="search-area"
          // style={{ marginLeft: "-10px", marginBottom: "1rem" }}
        >
          <form onSubmit={(e) => searchSumbitHandler(e)}>
            <div className="row">
              <div className="col-md-3 date-search-submit-btn">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="room_id"
                  onChange={searchChangeHandler}
                >
                  <option value="" selected>
                    Select Chamber
                  </option>
                  {roomList?.map((room) => {
                    return (
                      <option key={room?.id} value={room?.id}>
                        {room?.room_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3 date-search-submit-btn">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="sensor_id"
                  onChange={searchChangeHandler}
                >
                  <option value="" selected>
                    Select Sensor
                  </option>
                  {searchSensorList?.map((sensor) => {
                    return (
                      <option
                        key={sensor?.parameter_id}
                        value={sensor?.parameter_id}
                      >
                        {sensor?.sensor_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3 form-group theme-text">
                <label for="sensorUnit">Slave No.</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Insert Slave No."
                  name="slave_number"
                  onChange={searchChangeHandler}
                  // value={searchFormData?.slave_number || ""}
                />
              </div>
              <div className="col-md-2 date-search-submit-btn">
                <button type="submit" class="btn btn-primary col-md-12">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="sensor-map-button">
          <button
            type="submit"
            class="btn btn-primary col-md-12 "
            onClick={() => {
              setShow(true);
              setCreateFormSelector(true);
              setFormData({});
            }}
          >
            Sensor Map
          </button>

          {/* <button
            type="submit"
            className="btn btn-primary  "
            // style={{ width: "20vw" }}
            onClick={() => {
              setShow(true);
              setCreateFormSelector(true);
              setFormData({});
            }}
          >
            Sensor Map
          </button> */}

          {/* </div> */}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        classname={`${theme} `}
        centered
        onExited={handleModalOutsideClick}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>
              {createFormSelector ? "Sensor Map" : "Update Sensor Map"}
            </Modal.Title>
            <div style={{ color: "green" }}>{reseponseMessage}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
              <div>
                <div>
                  <form onSubmit={formSubmitHandler}>
                    <div className="form-group">
                      <label className="form-label">Select Room</label>
                      <select
                        class="form-control form-select"
                        name="room_id"
                        onChange={(e) => dataChangeHandler(e)}
                        required
                      >
                        <option label="Choose one"></option>
                        {roomList.map((roomInfo) => {
                          return (
                            <option value={roomInfo.id}>
                              {roomInfo.room_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="form-group">
                      <label for="sensorUnit">Level</label>
                      <input
                        type="text"
                        className="form-control"
                        name="level"
                        placeholder="Insert Level No."
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Select Device</label>
                      <select
                        class="form-control form-select"
                        name="device"
                        onChange={(e) => deviceChangeHandler(e)}
                      >
                        <option label="Choose one"></option>
                        {deviceList.map((deviceData) => {
                          return (
                            <option value={deviceData.id}>
                              {deviceData.device_code}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="form-label">Select Sensometer</label>
                      <select
                        class="form-control form-select"
                        name="sensometer"
                        onChange={(e) => sensometerChangeHandler(e)}
                        // value=""
                      >
                        <option label="Choose one"></option>
                        {sensometerList.map((sensometerInfo) => {
                          return (
                            <option value={sensometerInfo.senso_meter_id}>
                              {sensometerInfo.senso_meter_id}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="form-label">Select Sensor</label>
                      <select
                        class="form-control form-select"
                        name="sensor"
                        onChange={(e) => sensorChangeHandler(e)}
                      >
                        <option label="Choose one"></option>
                        {sensorList.map((sensorInfo) => {
                          return (
                            <option value={sensorInfo.sensor_id}>
                              {sensorInfo.sensor_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="form-label">Select Slave</label>
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        // defaultValue={[options[4], options[5]]}
                        isMulti
                        options={slaveList}
                        name="salve_number[]"
                        onChange={(e) => slaveChangeHandler(e)}
                        required
                        styles={customStyles}
                      />
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div>
        <div className="table-responsive theme-background theme-text">
          {/* <table
          style={{ textAlign: "center" }}
          className="table editable-table table-nowrap table-bordered table-edit theme-background theme-text "
        >
          <thead className="theme-background theme-text">
            <tr className="theme-background theme-text">
              <th style={{ color: theme == "dark" ? "black" : "white" }}>
                Sensor Name
              </th>
              <th style={{ color: theme == "dark" ? "black" : "white" }}>
                Sensor Id
              </th>
              <th style={{ color: theme == "dark" ? "black" : "white" }}>
                Sensor Unit
              </th>
              <th style={{ color: theme == "dark" ? "black" : "white" }}>
                Edit
              </th>
            </tr>
          </thead>
          <tbody>
            <tr data-id="1">
              <td data-field="sensorName">dummyName</td>
              <td data-field="sensorId">dummyId</td>
              <td data-field="sensorUnit">dummyUnit</td>
              <td style={{ width: "100px" }}>
                <a
                  className="btn btn-primary fs-14 text-white edit-icn"
                  title="Edit"
                  onClick={() => {
                    setShow(true);
                    setName("dummyName");
                    setId("dummyId");
                    setUnit("dummyUnit");
                  }}
                >
                  <FaEdit />
                </a>
              </td>
            </tr>
          </tbody>
        </table> */}
          <Table
            height={500}
            data={data || []}
            loading={defaultData.length > 0 ? false : true}
          >
            <Column
              width={50}
              align="center"
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Id.</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Room Name</HeaderCell>
              <Cell dataKey="room_name" />
            </Column>
            <Column
              width={50}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Level</HeaderCell>
              <Cell dataKey="level" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Device ID </HeaderCell>
              <Cell dataKey="device_code" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Sensometer Id{" "}
              </HeaderCell>
              <Cell dataKey="senso_meter_id" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Sensor Name</HeaderCell>
              <Cell dataKey="sensor_name" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Slave No.</HeaderCell>
              <Cell dataKey="slave_number" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Last Updated</HeaderCell>
              <Cell dataKey="updated_at" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Delete</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="delete"
                    onClick={() => {
                      // setShow(true);
                      // setCreateFormSelector(false);

                      handleDelete(rowData.id);
                    }}
                  >
                    <AiFillDelete />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SensorMap;
