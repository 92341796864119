import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers, { getState, endpoint }) => {
      const token = getState()?.auth?.accessToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: [],
  endpoints: (builder) => ({}),
});

export const apiSlice2 = createApi({
  reducerPath: "api2",
  baseQuery: fetchBaseQuery({
    // baseUrl: process.env.REACT_APP_API_URL,
    baseUrl: "https://testserver.sensometer.aqualinkbd.com",

    prepareHeaders: async (headers, { getState, endpoint }) => {
      //   const token = "661|fGyxsxK98KRFTcdAbtIcq4kkGoRoXJfhg9iclB0s";
      const token = getState()?.auth?.accessToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: [],
  endpoints: (builder) => ({}),
});
